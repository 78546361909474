import React, { useEffect, useContext } from 'react';
import { SettingsContext } from '../contexts';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../styles/index';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Loadable from 'react-loadable';
import { useInView } from 'react-intersection-observer';
import SEO from '../components/shared/SEO';
import { useGlobalComponentsQuery } from '../hooks/index';
import PhilosophyHero from '../components/philosophy/PhilosophyHero';
import PhilosophyIntroductionSection from '../components/philosophy/PhilosophyIntroductionSection';

const PosterSection = Loadable({
  loader: () => import('../components/philosophy/PhilosophyPosterSection'),
  loading() {
    return <div />;
  },
});

const PhilosophyBody = Loadable({
  loader: () => import('../components/philosophy/PhilosophyBody'),
  loading() {
    return <div />;
  },
});

const PhilosophyOutro = Loadable({
  loader: () => import('../components/philosophy/PhilosophyOutro'),
  loading() {
    return <div />;
  },
});

const PhilosophyCollection = Loadable({
  loader: () => import('../components/philosophy/PhilosophyCollection'),
  loading() {
    return <div />;
  },
});

export interface philosophyProps {
  data: {
    contentfulPagePhilosophy: {
      title: string;
      seoTitle: string;
      seoDescription: string;
      heroVideoVimeo: string;
      heroVideoYoutube: string;
      heroVideoVimeoTitle: string;
      introductionHeading: {
        json: string;
      };
      introductionParagraph: {
        introductionParagraph: string;
      };
      introductionMedia: {
        fluid: string;
      };
      posterImage: {
        description: string;
        fluid: string;
      };
      bodyText: {
        bodyText: string;
      };
      bodyMedia: {
        fluid: string;
      };
      bodyMediaFullWidth: {
        fluid: string;
      };
      outroHeading: {
        json: string;
      };
      outroMedia: {
        fluid: string;
      };
      collectionGrid: {
        title: string;
        items: {
          title: string;
          titleToShow: string;
          slug: string;
          productTag: string;
          shopifyData: {
            variants: {
              price: string;
              compare_at_price: string;
            };
          };
          thumbnailImages: {
            fluid: string;
          };
        };
      };
    };
  };
}

const Philosophy = ({ data: { contentfulPagePhilosophy } }: philosophyProps) => {
  const {
    title,
    seoTitle,
    seoDescription,
    heroVideoVimeo,
    heroVideoYoutube,
    heroVideoVimeoTitle,
    introductionHeading,
    introductionParagraph,
    introductionMedia,
    posterImage,
    bodyText,
    bodyMedia,
    bodyMediaFullWidth,
    outroHeading,
    outroMedia,
    collectionGrid,
  } = contentfulPagePhilosophy;

  const {
    renderPhilosophy,
    setRenderPhilosophy,
  }: {
    renderPhilosophy: any;
    setRenderPhilosophy: any;
  } = useContext(SettingsContext);

  const global = useGlobalComponentsQuery();

  const [pageMid, pageMidInView] = useInView({ threshold: 0 });
  const [trigger, triggerInView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (renderPhilosophy) {
      return;
    } else {
      if (pageMidInView || triggerInView) setRenderPhilosophy(true);
    }
  }, [pageMidInView, triggerInView]);

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} image={global?.seoImage.file.url} />
      <PhilosophyHero
        heroVideoVimeo={heroVideoVimeo}
        heroVideoVimeoTitle={heroVideoVimeoTitle}
        heroVideoYoutube={heroVideoYoutube}
      />
      <PhilosophyIntroductionSection
        introductionHeading={introductionHeading}
        introductionParagraph={introductionParagraph}
        introductionMedia={introductionMedia}
      />
      <PageMid ref={pageMid}>
        <Trigger ref={trigger} />
        {renderPhilosophy && (
          <>
            <PosterSection posterImage={posterImage} />
            <PhilosophyBody
              bodyText={bodyText}
              bodyMedia={bodyMedia}
              bodyMediaFullWidth={bodyMediaFullWidth}
            />
            <PhilosophyOutro outroHeading={outroHeading} outroMedia={outroMedia} />
            <PhilosophyCollection collectionGrid={collectionGrid} />
          </>
        )}
      </PageMid>
    </>
  );
};

const Trigger = styled.div`
  position: absolute;
  top: ${mobileVW(-800)};
  left: 0;
  height: 200px;
  z-index: ${zIndex.basement};

  ${desktopBreakpoint} {
    top: ${desktopVW(-600)};
  }
`;

const PageMid = styled.div`
  min-height: 100vh;
`;

export default Philosophy;

export const PhilosophyPageQuery = graphql`
  query PhilosophyPage($id: String!) {
    contentfulPagePhilosophy(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroVideoVimeo
      heroVideoYoutube
      heroVideoVimeoTitle
      introductionHeading {
        json
      }
      introductionParagraph {
        introductionParagraph
      }
      introductionMedia {
        fluid(maxWidth: 800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      posterImage {
        description
        fluid(maxWidth: 1800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bodyText {
        bodyText
      }
      bodyMedia {
        fluid(maxWidth: 1000, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bodyMediaFullWidth {
        fluid(maxWidth: 1800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      outroHeading {
        json
      }
      outroMedia {
        fluid(maxWidth: 800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      collectionGrid {
        title
        items {
          title
          titleToShow
          slug
          productTag
          shopifyData {
            variants {
              price
              compare_at_price
              inventory_quantity
            }
          }
          thumbnailImages {
            fluid(maxWidth: 810, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
